export default class Pagina{
    IdPagina;
    Lingua;
    TipoPagina;
    Testo;
    Titolo;
    UrlImmagine;
    Sottotitolo;
    Permalink;

    constructor(){
        this.Testo = "";
        this.Titolo = "";
    }
}