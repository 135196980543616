<template>
     <div class="Widget StandardBox WideImage">
        <div class="WidgetImage">
            <img :src="this.urlImmagineFull"  alt="" />
        </div>
    </div>
    <div class="Widget StandardBox">
        <div class="WidgetText">
            <h2>{{ this.widgetData.Titolo}}</h2>
            <div v-html="this.widgetData.Testo">                
            </div>
        </div>
    </div>
</template>

<script>
import {widgetMixin} from "@/mixin.js";

export default {
    name: "WidgetWideImage",
    props: {
        widgetData: Object
    },
    mixins: [widgetMixin]

}
</script>