import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL;

class MenuService{

    async getPaginaByPermalink( permalink ){

        return axios({
            method: 'get',
            url: API_URL + 'b2c/Pagine/GetPaginaByPermalink', 
            params:{               
                lingua: "IT",
                permalink: permalink
            },           
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(data =>  {            
            if(data.status && data.status == 401){
                return false;
            }
            return data.data
        });
    }

    async getWidgetsByIdPagina( idPagina ){

        return axios({
            method: 'get',
            url: API_URL + 'b2c/Pagine/GetWidgetByIdPagina?idPagina=' + idPagina , 
            params:{               
                idPagina: idPagina               
            },           
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(data =>  {            
            if(data.status && data.status == 401){
                return false;
            }
            return data.data
        });
    }
}

export default new MenuService();