<template>
    <div class="Widget StandardBox DxImage">
        <div class="WidgetImage"><img :src="this.urlImmagineFull" alt="" /></div>
        <div class="WidgetText">
            <h2>{{ this.widgetData.Titolo}}</h2>           
            <div v-html="this.widgetData.Testo"></div>
        </div>
    </div>
</template>

<script>

import {widgetMixin} from "@/mixin.js";

export default {
    name: "WidgetDxImage",
    props: {
        widgetData: Object
    },
    mixins: [widgetMixin]
}
</script>