<template>
    <Header/>
    <!-- <Breadcrumbs :extraData="this.pageData.Titolo"></Breadcrumbs> -->
    <section class="Section">
        <div class="Container">
            <div class="Box">
                <div class="Frame">
                    <div class="loader loaderPageDetails" v-show="this.isLoading"></div> 
                    <div v-show="this.showPage">
                        <h1>{{ this.pageData.Titolo }}</h1>
                        <div class="Widget MainText" v-html="this.pageData.Testo">                          
                        </div>                       
                        <template v-for="widget in this.pageWidgets" :key="widget.IdWidget">

                            <WidgetDxImage v-if="widget.TipoWidget == 'IMG_DX'" :widgetData="widget"></WidgetDxImage>
                            <WidgetSxImage v-if="widget.TipoWidget == 'IMG_SX'" :widgetData="widget"></WidgetSxImage>
                            <WidgetWideImage v-if="widget.TipoWidget == 'IMG_WIDE'" :widgetData="widget"></WidgetWideImage>
                            <WidgetStandardBox v-if="widget.TipoWidget == 'TESTO'" :widgetData="widget"></WidgetStandardBox>
                            <WidgetStandardBox v-if="widget.TipoWidget == 'TITOLO'" :widgetData="widget"></WidgetStandardBox>
                            <WidgetStandardBox v-if="widget.TipoWidget == 'PAR_SEMP'" :widgetData="widget"></WidgetStandardBox>
                        </template>
                    </div>    
                </div>
            </div>
            <div class="WhiteSpace Big">&nbsp;</div>
        </div>
    </section>
    <Footer/>

</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import PagineService from "@/services/pagine.service.js";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import store from '@/store';
import Pagina from "@/models/Pagina.model.js";
import PaginaWidget from "@/models/PaginaWidget.model.js";
import WidgetDxImage from "@/components/widgets/WidgetDxImage.vue";
import WidgetSxImage from "@/components/widgets/WidgetSxImage.vue";
import WidgetWideImage from "@/components/widgets/WidgetWideImage.vue";
import WidgetStandardBox from "@/components/widgets/WidgetStandardBox.vue";

export default {
    name: "PageDetails",
    data(){
        return {
            pageData: new Pagina(),
            pageWidgets: null,
            isLoading: false,
            showPage: false
        }
    },
    components: {
        Header,
        Footer,
        Breadcrumbs,
        WidgetDxImage,
        WidgetSxImage,
        WidgetWideImage,
        WidgetStandardBox,        
    },
    methods: {
        setPageData(data){
            this.isLoading = true;  
            this.showPage = false;         
            let pag = new Pagina();
            Object.assign(pag, data)
            this.pageData = data;
            this.getWidgets( this.pageData.IdPagina );
        },
        getWidgets: function( idPagina ){
            PagineService.getWidgetsByIdPagina( idPagina ).then(
                data => { 
                    if(!data){
                        this.$router.push("/");     
                    }
                    else{    
                        this.pageWidgets = data.Data.map(w => {
                            let widget = new PaginaWidget();                            
                            return Object.assign(widget, w);
                        });                
                        this.isLoading = false;
                        this.showPage = true;
                    }                  
                    
                }
            )
            .catch(error => {console.log(error)});            
        }
    },   
    beforeRouteEnter (to, from, next) {      
        let permalink = to.params.permalink;       
        PagineService.getPaginaByPermalink( permalink ).then(
            data => { 
                if(!data){
                    next("/")
                }
                else{                    
                    next(c => c.setPageData(data.Data));
                }
            }

        )
        .catch(error => {console.log(error)});

    },
    beforeRouteUpdate (to, from, next) {
        let permalink = to.params.permalink;       
        PagineService.getPaginaByPermalink(permalink).then(
            data => { 
                if(!data){
                    next("/")
                }
                else{    
                    this.setPageData(data.Data);                          
                    next();
                }
            }
        )
        .catch(error => {console.log(error)});

    }
}
</script>