<template>
    <section class="Section AriannaHolder" id="SecArianna">
        <div class="Container">
            <div class="Box">
                <div class="Arianna">                    
                    <template v-for="(link, index) in this.linkPagine" :key="index">
                        <template v-if="index < this.linkPagine.length-1">
                            <span>
                            <!-- <router-link :to="{'path' : link.to}">{{link.text}}</router-link> -->
                            </span>
                            <span class="AriannaSeparatore">&nbsp;</span>
                        </template>
                        <template v-else>
                            <span>{{link.text  + ((this.extraData) ? " " + this.extraData : "")}}</span>  
                        </template>
                    </template>    
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default{
    name : "BreadCrumbs",
    data(){
        return {
            linkPagine : [],
            lastLinkDescription : ""
        }
    },  
    mounted: function(){
        this.linkPagine = this.$route.meta.breadCrumbs;
    },
    props:{
        extraData: String
    }
}
</script>

<style>
    #SecArianna{
        margin-bottom: 15px;
    }
</style>
